export default {
  multiSheetWorkbooks: true,
  chartsNavigation: true,
  hideQuestionRules: process.env.NEXT_PUBLIC_SUPPORT_HIDE_QUESTION_RULES === 'true',
  googleAuthentication: process.env.NEXT_PUBLIC_SUPPORT_GOOGLE_AUTH === 'true',
  googlePicker: process.env.NEXT_PUBLIC_SUPPORT_GOOGLE_PICKER === 'true',
  googleDriveSync: process.env.NEXT_PUBLIC_SUPPORT_GOOGLE_DRIVE_SYNC === 'true',
  sharedWorkbooksSelect: process.env.NEXT_PUBLIC_SUPPORT_SHARED_WORKBOOKS_SELECT === 'true',
  widgetEditorThemeSelect: process.env.NEXT_PUBLIC_SUPPORT_WIDGET_EDITOR_THEMES_SELECT === 'true',
  lineChartSelectionsV2: process.env.NEXT_PUBLIC_SUPPORT_LINE_CHART_SELECTIONS_V2 === 'true',
  lineChartTemplates: process.env.NEXT_PUBLIC_SUPPORT_LINE_CHART_TEMPLATES === 'true',
  lineChartBasicDataEditor: process.env.NEXT_PUBLIC_SUPPORT_LINE_CHART_BASIC_DATA_EDITOR === 'true'
};