import flatten from 'lodash/flatten';
import merge from 'lodash/merge';
import { createNextIndexGenerator } from '@/utils/numbers';
import { filterCollapsedGroups, getDataBoundaries } from '../../internal/utils';
import { fillDataset, fillEmptyDataset } from '../../internal/BarChart/utils';
import { createSeriesData } from './utils';
export function createData(_ref) {
  let {
    results,
    seriesOptions,
    globalSeriesOptions = {},
    collectionOptions = [],
    colorPallet,
    columnLabels = [],
    legends = []
  } = _ref;
  let valueAxisTemplate = '';
  const collections = results.map((collection, collectionIndex) => {
    const getNextColorIndex = createNextIndexGenerator();
    return collection.map(selectionResultGroup => {
      const {
        selectionIndex
      } = selectionResultGroup;
      const options = merge({}, globalSeriesOptions, seriesOptions[selectionIndex] || {}); // Use the first template found
      // as the template for the value axis

      if (options.template && !valueAxisTemplate) {
        valueAxisTemplate = options.template;
      } // Lookup any collection options


      const collectionOption = collectionOptions[collectionIndex] || {};
      return {
        group: selectionResultGroup,
        options,
        selectionIndex,
        collectionOption
      };
    }) // Filter collapsed
    .filter(_ref2 => {
      let {
        group,
        options
      } = _ref2;
      return filterCollapsedGroups(group, options);
    }) // Create series data
    .map(_ref3 => {
      let {
        group,
        options,
        collectionOption,
        selectionIndex
      } = _ref3;
      return createSeriesData({
        group,
        options,
        collectionOption,
        selectionIndex,
        colorPallet,
        getNextColorIndex
      });
    });
  }); // Labels are shared across all datasets

  const labels = [];
  let legendsCount = 0; // The number of datasets should match
  // the maximum number of series in any group.
  // If a group has less series than the maximum,
  // fill the dataset with empty data.

  const datasets = [];
  collections.forEach((groupSelections, groupIdx) => {
    const group = flatten(groupSelections);
    const collectionOption = collectionOptions[groupIdx] || {};
    const label = collectionOption.label || columnLabels[groupIdx] || '';
    labels.push(label);
    group.forEach((seriesData, datasetIdx) => {
      if (!datasets[datasetIdx]) {
        legendsCount += 1; // Create empty dataset

        datasets[datasetIdx] = {
          data: [],
          backgroundColor: [],
          templates: [],
          titles: [],
          selections: [],
          selectionIndexes: [],
          datalabels: [],
          borderColor: [],
          borderWidth: [],
          label: legends[datasetIdx] || `Legend ${datasetIdx + 1}`
        };
      } // fill the dataset with empty data if needed
      // to match the max amount of series in the group


      if (datasetIdx > 0 && datasets[datasetIdx].data.length < groupIdx) {
        const emptyDatasetsCount = groupIdx - datasets[datasetIdx].data.length;

        for (let i = 0; i < emptyDatasetsCount; i++) {
          fillEmptyDataset(datasets[datasetIdx]);
        }
      }

      fillDataset(seriesData, datasets[datasetIdx]);
    });
  }); // Calculate min/max of all datasets

  const dataBoundaries = getDataBoundaries(datasets, true);
  return {
    legendsCount,
    labels: labels,
    datasets: datasets,
    dataBoundaries: dataBoundaries,
    valueAxisTemplate: valueAxisTemplate
  };
}