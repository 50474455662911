import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { deepArrayMap } from '@/utils/array';
import { spreadsheet as spreadsheetCore } from '@industriousapps/excelkits-core';
const {
  splitCellRefs,
  prependSheetIndex,
  getSpreadsheetIndex,
  toCellRefs
} = spreadsheetCore;
const PREFIX = 'common: utils: widget: parseSelections:';
// Convert a selection string into selection collections
// which may contain deeply nested groups of selections
// NOTE: arbatrary nesting of selections is supported
export default function parseSelections(selections) {
  const parseableSelections = selections.split('|') // Split collections
  .map(collection => `[${collection}]`).join(',');
  return parseSelectionsAsJson(`[${parseableSelections}]`);
} // Convert stringified selections to collections

export const decodeSelections = parseSelections; // Convert selections to string

export function encodeSelections(selectionCollections) {
  if (typeof selectionCollections === 'string') {
    return selectionCollections;
  }

  return encodeDeepSelectionMapper(selectionCollections, 0).join('|');
} // Recursively itterate over parsed selection
// segment, combining it into a joined strings

function encodeDeepSelectionMapper(arr, depth) {
  const result = [];

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const isArrayItem = Array.isArray(item);

    if (isArrayItem && item.every(s => typeof s === 'string')) {
      // Combine flat selection array
      result.push(`${depth > 0 ? '[' : ''}${item.join(',')}${depth > 0 ? ']' : ''}`);
    } else if (isArrayItem) {
      result.push(`${depth > 0 ? '[' : ''}${encodeDeepSelectionMapper(item, depth + 1).join(',')}${depth > 0 ? ']' : ''}`);
    } else {
      result.push(item);
    }
  }

  return result;
} // Reassemble results in the same form as the selection
// collection inserting recovered results into the same
// index as it's originating selection


export function findAndReplaceSelections(selectionCollections, selectionResults) {
  const stringifiedResults = JSON.stringify(deepArrayMap(selectionCollections, (selections, flatIndex) => {
    const foundResults = selectionResults.filter(_ref => {
      let {
        selectionIndex
      } = _ref;
      return flatIndex === selectionIndex;
    }); // When no results found, replace
    // selection with a blank result

    if (!foundResults.length) {
      foundResults.push({
        selections,
        selectionIndex: flatIndex,
        data: [{
          value: '',
          cell: splitCellRefs(selections).pop()
        }]
      });
    } // Replace selection string with the series
    // of selection results it is responsible for


    return foundResults.map(group => {
      // Remove any double quotes in value
      // to make the value parsable as JSON
      return JSON.stringify(_objectSpread(_objectSpread({}, group), {}, {
        data: group.data.map(result => {
          if (typeof result.value !== 'string') return result;
          return _objectSpread(_objectSpread({}, result), {}, {
            value: result.value.replace(/"/g, '')
          });
        })
      }));
    }).join(',');
  }), (_key, value) => stringifyResultStringArray(value)).replace(/^"|"$/g, '').replace(/\\/g, '');

  try {
    return JSON.parse(stringifiedResults);
  } catch (err) {
    throw Error(`${PREFIX} findAndReplaceSelections: failed to parse stringified results: ${err}`);
  }
} // Stringify array and content without
// wrapping items in extra strings

function stringifyResultStringArray(arr) {
  const result = [];

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const isArrayItem = Array.isArray(item);

    if (isArrayItem && item.every(v => typeof v === 'string')) {
      result.push(`[${item.join(',')}]`);
    } else if (isArrayItem) {
      result.push(stringifyResultStringArray(item));
    } else {
      result.push(item);
    }
  }

  return `[${result.join(',')}]`;
} // NOTE: Assumes last cell of 1st range
// is the value cell (for labeled selection)
// otherwise returns first cell selection
// (for non-labeled selection)


export function toLastCellReferenceSelection(selection) {
  const ranges = toCellRefs(selection).split(':');
  const sheetIndex = getSpreadsheetIndex(selection);
  return prependSheetIndex(ranges[1] || ranges[0], sheetIndex);
} // Wrap add cell references with string
// quotations so that they are parsable as
// JSON, then parse the result
// NOTE: this is a workaround for spotty support
// for negative lookaheads, replaceAll, & matchAll

function parseSelectionsAsJson(selections) {
  let tmpl = `${selections}`;
  let query = tmpl;
  const selectionRefs = []; // Collect matches to cell references

  let match;

  while (match = /(-?\d*\.?[A-Za-z0-9]+:?[A-Za-z]*\d*)/g.exec(query)) {
    selectionRefs.push(match[0]);
    const startIdx = Math.max(0, match.index);
    const endIdx = match.index + match[0].length;
    const val = `${selectionRefs.length - 1}`;
    tmpl = tmpl.substring(0, startIdx) + `{{${val}}}` + // add interpolation targets
    tmpl.slice(match.index + match[0].length); // Continue search for valid selectors replacing
    // consumed selectors with ignored "_" characters
    // mimicing char length of resulting template

    query = `${query.substring(0, startIdx)}{{${val.replace(/\d/g, '_')}}}${query.slice(endIdx)}`;
  } // Replace interpolation targets with quoted string
  // to make the cell reference parsable as JSON


  for (let i = 0; i < selectionRefs.length; i++) {
    const ref = selectionRefs[i];
    tmpl = tmpl.replace(`{{${i}}}`, `"${ref}"`);
  }

  try {
    return JSON.parse(tmpl);
  } catch (err) {
    throw Error(`${PREFIX} parseSelectionsAsJson: failed to parse selections: ${selections}`);
  }
}