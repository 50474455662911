import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { merge, camelCase } from 'lodash';
import { useMemo } from 'react';
import { ThemeId } from '@/interfaces/theme';
import design, { remCalc } from '@/config/design';
import { deleteUndefinedValues } from '@/utils/objects';
import { useTheme } from '@/components/widget/Layout/ThemeProvider';
const defaultStyles = Object.freeze({
  chartBackgroundColor: design.colors.white,
  tableHorizontalBorderWidth: '0px',
  tableBorderWidth: '0px',
  tableColumnPaddingLeft: '0px',
  tableColumnPaddingRight: '0px'
});
const shadcnDefaultStyles = Object.freeze({
  tableBorderWidth: remCalc(1),
  tableColumnPaddingVertical: remCalc(8),
  tableColumnPaddingLeft: remCalc(8),
  tableColumnPaddingRight: remCalc(8),
  tableColumnFontSize: remCalc(14),
  tableBorderRadius: remCalc(6)
});
export const getThemeDefaults = params => {
  return merge({}, defaultStyles, params.theme ? getStyleOverridesByTheme(params.theme, params.styles || {}) : {}, mapThemeStylesToSectionStyles(params.styles || {}));
};

const getStyleOverridesByTheme = (theme, styles) => ({
  [ThemeId.UniversalDesignLight]: {
    tableHeaderFontColor: styles['gray-500']
  },
  [ThemeId.UniversalDesignDark]: {
    tableHeaderFontColor: styles['gray-500']
  },
  [ThemeId.ShadcnLight]: shadcnDefaultStyles,
  [ThemeId.ShadcnDark]: shadcnDefaultStyles
})[theme];

const mapThemeStylesToSectionStyles = styles => {
  // Convert the theme style keys to camelCase
  const themeStyles = Object.keys(styles).reduce((acc, key) => {
    acc[camelCase(key)] = styles[key];
    return acc;
  }, {});
  return deleteUndefinedValues(_objectSpread(_objectSpread({}, themeStyles), {}, {
    chartBackgroundColor: styles['body-background-color']
  }));
};

export const useTableStyles = () => {
  const themeCtx = useTheme();
  const defaultStyles = useMemo(() => getThemeDefaults({
    theme: themeCtx.theme,
    styles: themeCtx.styles
  }), [themeCtx]);
  return {
    defaultStyles,
    themeCtx
  };
};