import { marked } from 'marked';
import { useMemo } from 'react';
import merge from 'lodash/merge';
import flatten from 'lodash/flatten';
import templateEngine from '@/utils/templateEngine';
import { selectionResultsToHash, seriesOptionsToHash, flatObjectHash } from '@/utils/widget/hashing';
export default function useDataReceipt(results, seriesOptions, globalSeriesOptions) {
  const resultsHash = selectionResultsToHash(results);
  const optionsHash = seriesOptionsToHash(seriesOptions);
  const globalOptionsHash = flatObjectHash([globalSeriesOptions]);
  return useMemo(() => {
    if (!results || !results.length || !results[0].length) {
      return {
        data: []
      };
    } // Only supports single-collection selections


    const dataGroups = results[0] // Pair selection group with series options
    .map((selectionResultGroup, index) => {
      const options = merge({}, globalSeriesOptions, seriesOptions[index] || {});
      return {
        group: selectionResultGroup,
        options
      };
    }) // Filter collapsed
    .filter((item, _, arr) => {
      const {
        group,
        options = {
          collapsible: false
        }
      } = item;
      const isLast = arr.indexOf(item) === arr.length - 1; // Filter out collapsed group
      // when all its' values are not truthy
      // NOTE: last item is non-collapsable

      if (options.collapsible && !isLast) {
        return group.data.some(item => Boolean(item.value));
      }

      return true;
    }) // Create series data
    .map(_ref => {
      let {
        group,
        options = {}
      } = _ref;
      const userLabels = options.labels || {}; // Provide default template

      const template = options.template || `{{value}}`; // Unsparated by default

      const separator = typeof options.separator === 'boolean' ? options.separator : false; // Convert each item in group
      // into a renderable item for
      // the visualization

      return group.data.map((item, index) => ({
        separator,
        label: marked.parseInline(`${userLabels[item.labelCell || ''] || ( // Check customized label cells
        index === 0 ? options.label : '') || // Check singular label for first item
        item.label || // Check spreadsheet label value
        ''}`),
        value: marked.parseInline(templateEngine(template, {
          value: item.value
        })),
        color: options.color || '',
        tooltip: options.tooltip || '',
        indented: options.indented || false,
        selection: group.selections,
        selectionIndex: group.selectionIndex
      }));
    });
    return {
      data: flatten(dataGroups)
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsHash, optionsHash, globalOptionsHash]);
}