import { useCallback } from 'react';
import { chartColorPallet as DEFAULT_COLORS } from '@/config/colorPallets/universalDesignSystem';
import templateEngine from '@/utils/templateEngine';
import { getColorAtPalletIndex } from '@/utils/colors';
export default function useChartActions(_ref) {
  let {
    legendsColorPallet = DEFAULT_COLORS,
    collectionOptions = [],
    xAxisTemplate,
    yAxisTemplate
  } = _ref;
  // Toggle visibility of column
  const toggleLegendVisibility = useCallback((_e, legendItem, legend) => {
    const ci = legend.chart;
    ci.toggleDataVisibility(legendItem.index);
    ci.update();
  }, []); // Generate unique legend labels with dataset indexes
  // for toggling visibility

  const generateLegendLabels = useCallback((chart, legends, color) => {
    return legends.map((legend, index) => {
      const collectionOption = collectionOptions[index] || {};
      return {
        text: legend,
        hidden: !chart.getDataVisibility(index),
        fillStyle: collectionOption.color || getColorAtPalletIndex(index, legendsColorPallet),
        index,
        fontColor: color
      };
    });
  }, [legendsColorPallet, collectionOptions]); // Custom tick callback for x axis

  const xAxisTicksCallback = useCallback(function (value) {
    const label = this.getLabelForValue(value);
    if (!xAxisTemplate) return label;
    return templateEngine(xAxisTemplate, {
      value: value
    });
  }, [xAxisTemplate]); // Custom tick callback for y axis

  const yAxisTicksCallback = useCallback(function (value) {
    const label = this.getLabelForValue(value);
    if (!yAxisTemplate) return label;
    return templateEngine(yAxisTemplate, {
      value: value
    });
  }, [yAxisTemplate]);
  return {
    toggleLegendVisibility,
    generateLegendLabels,
    xAxisTicksCallback,
    yAxisTicksCallback
  };
}