const PREFIX = 'exk';
export default {
  // START DEPRECATED
  questionnaireloading: `${PREFIX}:questionnaireloading`,
  questionnairesubmit: `${PREFIX}:questionnairesubmit`,
  questionnaireresubmit: `${PREFIX}:questionnaireresubmit`,
  questionnairesave: `${PREFIX}:questionnairesave`,
  // END DEPRECATED
  formloading: `${PREFIX}:formloading`,
  formsubmit: `${PREFIX}:formsubmit`,
  formresubmit: `${PREFIX}:formresubmit`,
  forminternalresubmit: `${PREFIX}:forminternalresubmit`,
  formsave: `${PREFIX}:formsave`,
  modalClose: `${PREFIX}:modalClose`,
  modalOpen: `${PREFIX}:modalOpen`,
  confirmationPromptOpen: `${PREFIX}:confirmationPromptOpen`,
  confirmationPromptClose: `${PREFIX}:confirmationPromptClose`,
  questionvalid: `${PREFIX}:questionvalid`,
  questioninvalid: `${PREFIX}:questioninvalid`,
  questionupdatevalue: `${PREFIX}:questionupdatevalue`,
  questionchange: `${PREFIX}:questionchange`,
  editingareaselect: `${PREFIX}:editingareaselect`,
  editingareadeselect: `${PREFIX}:editingareadeselect`,
  // Univer events
  univerSheetFinalizeChangesStart: `${PREFIX}:univerSheetFinalizeChangesStart`,
  univerSheetFinalizeChangesEnd: `${PREFIX}:univerSheetFinalizeChangesEnd`
};