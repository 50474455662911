export default {
  formats: {
    number: '#,##0.00',
    percent: '0.00%',
    scientific: '0.00E+00',
    accounting: '_("$"* #,##0.00_);_("$"* \\(#,##0.00\\);_("$"* "-"??_);_(@_)',
    financial: '#,##0.00;(#,##0.00)',
    currency: '"$"#,##0.00',
    currencyRounded: '"$"#,##0',
    date: 'M/d/yyyy',
    time: 'h:mm:ss am/pm',
    dateTime: 'M/d/yyyy H:mm:ss',
    duration: '[h]:mm:ss',
    minuteSecond: 'mm:ss',
    shortMinuteSecond: 'm":"s',
    currencies: {
      usd: '[$$]#,##0.00',
      gbp: '[$£]#,##0.00',
      cyr: '[$¥]#,##0.00',
      euro: '[$€]#,##0.00',
      yen: '[$¥]#,##0',
      chf: '[$CHF]#,##0.00'
    }
  }
};