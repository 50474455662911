import { marked } from 'marked';
import templateEngine from '@/utils/templateEngine';
import { groupSelectionResultsByIndex } from '../utils'; // Create columns from a single selection

export function createColumns(srcResults, selectionsLabeled) {
  // Only supports a single-collection
  const results = groupSelectionResultsByIndex(srcResults[0] || []);
  const columns = results.length;
  return Array(columns).fill(0).map((_, index) => ({
    Header: selectionsLabeled ? (((results[index] || {}).data || [])[0] || {}).label || '' : '',
    accessor: `col${index}`,
    // accessor is the "key" in the data
    selection: (results[index] || {}).selections || '',
    selectionIndex: (results[index] || {}).selectionIndex || 0
  }));
} // Create table data from a single collection
// of selections

export function createData(srcResults, seriesOptions, globalSeriesOptions) {
  // Only supports a single-collection
  const results = groupSelectionResultsByIndex(srcResults[0] || []);
  const columns = results.length; // Discover selection with most rows

  const rows = Math.max(0, results.reduce((acc, _ref) => {
    let {
      data
    } = _ref;
    return data.length > acc ? data.length : acc;
  }, 0));
  return Array(rows).fill(0).map((_, rowIndex) => {
    return Array(columns).fill(0).reduce((acc, _, colIndex) => {
      const options = seriesOptions[colIndex] || {};
      const columnData = (results[colIndex] || {}).data || [];
      const srcValue = (columnData[rowIndex] || {
        value: ''
      }).value;
      const value = srcValue !== undefined ? `${srcValue}` : '';
      const template = options.template || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.template) || '{{value}}'; // Interpolate the template

      acc[`col${colIndex}`] = marked.parseInline(templateEngine(template, {
        value
      }));
      acc[`colSelection${colIndex}`] = (results[colIndex] || {}).selections || '';
      acc[`colSelectionIndex${colIndex}`] = (results[colIndex] || {}).selectionIndex || 0;
      return acc;
    }, {});
  });
}