import { spreadsheet as spreadsheetCore } from '@industriousapps/excelkits-core';
import { createWrappedError } from '@/utils/errors';
export function getWorkbookSheets(workbook) {
  return workbook.getSheets();
}
const PREFIX = 'components: Spreadsheet: utils:'; // Convert to selection to range
// NOTE: disregards sheet selection

export function selectionToRange(selection, sheetDimensions) {
  const [first, last] = spreadsheetCore.cellReferencesToAddress(selection);
  const {
    width,
    height
  } = sheetDimensions || {
    width: Infinity,
    height: Infinity
  };
  const range = {
    startRow: Math.min(first.row, height - 1),
    startColumn: Math.min(first.col, width - 1),
    endRow: Math.min((last || first).row, height - 1),
    endColumn: Math.min((last || first).col, width - 1)
  };
  return range;
} // Defocus the active cell
// Note: Ensure that the data of the cell will be synchronized
// to the snapshot when it loses focus

export async function cancelEditing(univerAPI) {
  return univerAPI.executeCommand('sheet.operation.set-cell-edit-visible', {
    visible: false,
    _eventType: 2
  }).catch(err => {
    throw createWrappedError(`${PREFIX} cancelEditing: failed to cancel editing`, err);
  });
}