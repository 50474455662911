import { useMemo } from 'react';
import { flatObjectHash, selectionResultsToHash, seriesOptionsToHash } from '@/utils/widget/hashing';
import { createColumns as createTabularSelectionColumns, createData as createTabularSelectionData } from './tabularToResults';
import { createColumns as createColumnSelectionColumns, createData as createColumnSelectionData } from './columnsToResults';
import { createColumns as createRowSelectionColumns, createData as createRowSelectionData } from './rowsToResults';
export default function useTableData(config) {
  const {
    selectionsStrategy = 'default',
    selectionsLabeled,
    results = [],
    seriesOptions = [],
    globalSeriesOptions = {}
  } = config;
  const strategy = !selectionsStrategy ? 'default' : selectionsStrategy; // Create unique hashes for values
  // that may require a re-render

  const resultsHash = selectionResultsToHash(results);
  const optionsHash = seriesOptionsToHash(seriesOptions);
  const globalOptionsHash = flatObjectHash(globalSeriesOptions); // Determine # of columns in table
  // as well as the headers

  const columns = useMemo(() => {
    // Default selection is a single-selection table
    if (strategy === 'default') {
      return createTabularSelectionColumns(results, selectionsLabeled);
    }

    if (strategy === 'columns') {
      return createColumnSelectionColumns(results, selectionsLabeled);
    }

    if (strategy === 'rows') {
      return createRowSelectionColumns(results, seriesOptions);
    }

    return []; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategy, selectionsLabeled, resultsHash, optionsHash]); // Create the data in the table

  const data = useMemo(() => {
    // Default selection is a single-selection table
    if (strategy === 'default') {
      return createTabularSelectionData(results, seriesOptions, globalSeriesOptions);
    }

    if (strategy === 'columns') {
      return createColumnSelectionData(results, seriesOptions, globalSeriesOptions);
    }

    if (strategy === 'rows') {
      return createRowSelectionData(results, seriesOptions, globalSeriesOptions);
    }

    return [];
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [strategy, selectionsLabeled, resultsHash, optionsHash, globalOptionsHash]);
  return {
    data,
    columns,
    hasHeaders: columns.some(_ref => {
      let {
        Header
      } = _ref;
      return Boolean(Header);
    })
  };
}