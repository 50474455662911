import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { transparentize } from '@/utils/colors';
import { ThemeId, ThemeTag } from '@/interfaces/theme';
import { remCalc } from '../design';
const commonStyles = {
  'radius-base': 8,
  // Common Colors
  'gray-50': '#f9fafb',
  // $gray-5
  'gray-100': '#f3f4f6',
  // $gray-10
  'gray-200': '#e5e7eb',
  // $gray-20
  'gray-300': '#d1d5db',
  // $gray-30
  'gray-400': '#9ca3af',
  // $gray-40
  'gray-500': '#6b7280',
  // $gray-50
  'gray-600': '#4b5563',
  // $gray-60
  'gray-700': '#374151',
  // $gray-70
  'gray-800': '#1f2937',
  // $gray-80
  'gray-900': '#111827',
  // $gray-90
  'gray-950': '#030712',
  // $gray-100
  // Typography
  'body-font-family': 'Geist,ui-monospace,SFMono-Regular,Roboto Mono,Menlo,Monaco,Liberation Mono,DejaVu Sans Mono,Courier New,monospace',
  'heading-font-weight': 700,
  // bold
  'heading-letter-spacing': '-.05em',
  'heading-font-size': remCalc(30),
  'heading-line-height': '2rem',
  'subheading-font-size': remCalc(18),
  'subheading-line-height': remCalc(28),
  'subheading-font-weight': 300,
  // light
  'section-heading-font-size': remCalc(16),
  'section-heading-line-height': remCalc(16),
  // Form
  'input-height': remCalc(40),
  'input-font-weight': 400,
  'input-padding-top': remCalc(10),
  'input-padding-bottom': remCalc(10),
  'input-padding-left': remCalc(12),
  'input-font-size': remCalc(14),
  'input-line-height': remCalc(20),
  'input-outline-offset': '2px',
  'input-outline-width': '2px',
  'slider-height': '8px',
  'slider-mark-margin-top': remCalc(14),
  'slider-handle-border-width': '2px',
  'slider-handle-offset': remCalc(-4),
  'dropdown-link-font-size': remCalc(14),
  'dropdown-link-line-height': remCalc(16),
  'dropdown-link-vertical-padding': remCalc(12),
  'radio-button-vertical-padding': remCalc(11),
  'radio-button-horizontal-padding': remCalc(14),
  'radio-button-height': remCalc(40),
  'button-font-weight': 500,
  'button-border-radius': remCalc(8),
  // Charts
  'chart-axis-font-family': 'Geist,ui-monospace,SFMono-Regular,Roboto Mono,Menlo,Monaco,Liberation Mono,DejaVu Sans Mono,Courier New,monospace',
  'chart-axis-font-size': '.75rem',
  // 12px
  'chart-axis-font-weight': 500
};
const lightPrimaryForegroundColor = '#FAFAFA'; // Light theme styles

const lightStyles = _objectSpread(_objectSpread({}, commonStyles), {}, {
  'primary-color': '#1c1c1e',
  'primary-foreground-color': lightPrimaryForegroundColor,
  'border-color': '#e4e4e7',
  'border-color-focus': '#A1A1A9',
  'border-color-muted': '#e4e4e7',
  'scrollbar-color': '#e4e4e7',
  'chart-grid-color': '#f1f1f3',
  'chart-axis-font-color': '#6e6e77',
  'chart-data-label-color': '#09090b',
  'dropdown-divider-color': '#F4F4F5',
  'slider-rail-color': '#F4F4F5',
  'slider-mark-font-color': '#0F172A',
  // Form
  'radio-button-border-color': '#E3E8EF',
  'radio-button-active-border-color': '#E3E8EF',
  'radio-button-active-color': '#F2F5F9',
  'radio-button-active-font-color': '#030816',
  'input-placeholder-color': '#717179',
  'button-default-ring-color': '#A1A1A9',
  'button-default-background-color': '#18181B',
  'button-default-background-color-hover': transparentize('#18181B', 0.9),
  'button-default-foreground-color': lightPrimaryForegroundColor,
  // Typography
  'body-font-color': '#09090b',
  'muted-font-color': '#71717A',
  'heading-font-color': '#09090b',
  'subheading-font-color': '#71717A',
  // Table
  'table-header-font-color': '#64748b',
  'table-column-font-color': '#020817'
}); // Light theme color pallets


const lightThemeVariants = [{
  name: 'Default',
  colors: ['#299d90', '#e76e50', '#264753', '#e9c469', '#f4a362']
}, {
  name: 'Palette',
  colors: ['#e76e50', '#299d90', '#264753', '#e9c469', '#f4a362']
}, {
  name: 'Sapphire',
  colors: ['#2463eb', '#60a8fa', '#3a87f7', '#91c6fe', '#bddcfe']
}, {
  name: 'Ruby',
  colors: ['#e21d48', '#fbd5da', '#f17e92', '#f7abb6', '#e9536f', '#e9536f']
}, {
  name: 'Emerald',
  colors: ['#125427', '#1dc355', '#098637', '#113b1d', '#0e2014']
}, {
  name: 'Daylight',
  colors: ['#60432f', '#765337', '#8f633d', '#ad7c48', '#bb9054']
}, {
  name: 'Midnight',
  colors: ['#e6e5e5', '#bcbcbd', '#939395', '#6a6a6c', '#414144']
}];
const darkBorderColor = '#27272a';
const darkPrimaryColor = '#fafafa';

const darkStyles = _objectSpread(_objectSpread({}, commonStyles), {}, {
  'primary-color': darkPrimaryColor,
  'primary-foreground-color': '#09090b',
  'body-background-color': '#09090b',
  'border-color': darkBorderColor,
  'border-color-muted': darkBorderColor,
  'border-color-focus': darkBorderColor,
  'dropdown-border-color': darkBorderColor,
  'dropdown-divider-color': darkBorderColor,
  'input-outline-color': '#D4D4D8',
  'scrollbar-color': darkBorderColor,
  'chart-grid-color': '#18181a',
  'chart-axis-font-color': '#a1a1aa',
  'chart-data-label-color': darkPrimaryColor,
  'slider-rail-color': '#27272A',
  'slider-mark-font-color': darkPrimaryColor,
  // Form
  'input-placeholder-color': '#A1A1A9',
  'radio-button-border-color': '#20293A',
  'radio-button-active-border-color': '#20293A',
  'radio-button-active-color': '#20293A',
  'radio-button-active-font-color': '#F8FAFC',
  'button-default-background-color': darkPrimaryColor,
  'button-default-background-color-hover': transparentize(darkPrimaryColor, 0.9),
  'button-default-foreground-color': '#18181B',
  'button-default-ring-color': '#D4D4D8',
  // Typography
  'body-font-color': darkPrimaryColor,
  'muted-font-color': '#a1a1aa',
  'heading-font-color': darkPrimaryColor,
  'subheading-font-color': '#a1a1aa',
  // Table
  'table-header-font-color': '#94a3b8',
  'table-column-font-color': '#f8fafc'
}); // Light theme color pallets


const darkThemeVariants = [{
  name: 'Default',
  colors: ['#2662d9', '#e23670', '#e88c30', '#af57db', '#2eb88a']
}, {
  name: 'Palette',
  colors: ['#2662d9', '#e23670', '#e88c30', '#af57db', '#e23670']
}, {
  name: 'Sapphire',
  colors: ['#2563eb', '#60a8fb', '#3b86f7', '#90c7fe', '#bedcfe']
}, {
  name: 'Ruby',
  colors: ['#e21d48', '#e9536f', '#f17e92', '#f7abb6', '#fbd5da', '#fbd5da']
}, {
  name: 'Emerald',
  colors: ['#098637', '#125427', '#106a2e', '#113b1d', '#0e2014']
}, {
  name: 'Daylight',
  colors: ['#60432f', '#765337', '#8f633d', '#ad7c48', '#bb9054']
}, {
  name: 'Midnight',
  colors: ['#e6e5e5', '#bcbcbd', '#939395', '#6a6a6c', '#414144']
}]; // Theme Fonts

const fonts = [{
  fontName: 'Geist',
  variants: [{
    italic: false,
    weight: 300
  }, {
    italic: false,
    weight: 400
  }, {
    italic: false,
    weight: 500
  }, {
    italic: false,
    weight: 600
  }, {
    italic: false,
    weight: 700
  }]
}];
export default {
  light: {
    id: ThemeId.ShadcnLight,
    name: 'Shadcn',
    styles: lightStyles,
    colorPallets: lightThemeVariants,
    tags: [ThemeTag.Light],
    fonts: fonts
  },
  dark: {
    id: ThemeId.ShadcnDark,
    name: 'Shadcn',
    styles: darkStyles,
    colorPallets: darkThemeVariants,
    tags: [ThemeTag.Dark],
    fonts: fonts
  }
};