import googleSheets from '@/config/googleSheets';
export const DOLLAR_FMT = {
  label: '$',
  value: googleSheets.formats.currency
};
export const US_DOLLAR_FMT = {
  label: 'US Dollar',
  value: googleSheets.formats.currencies.usd,
  ticker: '$'
};
export const JAPANESE_YEN_FMT = {
  label: 'Japanese Yen',
  value: googleSheets.formats.currencies.yen,
  ticker: '¥'
};
export const CURRENCY_ROUNDED_FMT = {
  label: 'Currency Rounded',
  value: googleSheets.formats.currencyRounded,
  ticker: '$'
};
export const CURRENCY_FMTS = [DOLLAR_FMT, US_DOLLAR_FMT, {
  label: 'Australian Dollar',
  value: googleSheets.formats.currency,
  ticker: 'AUD'
}, {
  label: 'British Pound Sterling',
  value: googleSheets.formats.currencies.gbp,
  ticker: '£'
}, {
  label: 'Canadian Dollar',
  value: googleSheets.formats.currency,
  ticker: 'CAD'
}, {
  label: 'Chinese Yuan',
  value: googleSheets.formats.currencies.cyr,
  ticker: 'CNY'
}, {
  label: 'Euro',
  value: googleSheets.formats.currencies.euro,
  ticker: '€'
}, {
  label: 'Hong Kong Dollar',
  value: googleSheets.formats.currency,
  ticker: 'HKD'
}, JAPANESE_YEN_FMT, {
  label: 'New Zealand Dollar',
  value: googleSheets.formats.currency,
  ticker: 'NZD'
}, {
  label: 'Swiss Franc',
  value: googleSheets.formats.currencies.chf,
  ticker: 'CHF'
}];
export const NUMBER_FMTS = [{
  label: 'Number',
  value: googleSheets.formats.number
}, {
  label: 'Scientific',
  value: googleSheets.formats.scientific
}, {
  label: 'Accounting',
  value: googleSheets.formats.accounting
}, {
  label: 'Financial',
  value: googleSheets.formats.financial
}, {
  label: 'Date',
  value: googleSheets.formats.date
}, {
  label: 'Time',
  value: googleSheets.formats.time
}, {
  label: 'Date Time',
  value: googleSheets.formats.dateTime
}, {
  label: 'Duration',
  value: googleSheets.formats.duration
}, {
  label: 'Minute/Second',
  value: googleSheets.formats.minuteSecond,
  ticker: 'Min/Sec'
}];
export const PERCENT_FMT = {
  label: '%',
  value: googleSheets.formats.percent
};
export const FORMATS = [...CURRENCY_FMTS, ...NUMBER_FMTS, PERCENT_FMT];
export const errorCodeMessages = {
  // Division by zero
  DIV_BY_ZERO: 'This happens when a mathematical operation attempts to divide by zero',
  // Unknown function name
  NAME: 'This happens when a function you entered does not exist or is unsupported',
  VALUE: 'This error occurs whenever the data type a function is given doesn’t match what it is expecting',
  NUM: 'The number is too large or too small to be displayed',
  NA: 'Occurs when a function cannot find the value it is being asked to look for',
  // Cyclic dependency
  CYCLE: "Means there's a circular reference. This happens when a formula refers back to its own cell value",
  // Wrong reference.
  REF: 'This happen when a cell reference is deleted or moved',
  // Array spill error.
  SPILL: 'Happens when the formula is applied to the data because there is text or some data inside the spill range',
  // Calculation error.
  CALC: "Happens when a formula doesn't know how to calculate",
  // Generic error
  ERROR: 'An unspecified or unknown error occured',
  // In the case of SUM(B1 C1)
  NULL: 'Occurs requested the result of overlapping ranges'
};