import { config } from '@industriousapps/excelkits-core';
import { createWrappedError } from '@/utils/errors';
const PREFIX = 'common: utils: strings:';
const {
  ordinals
} = config; // Convert a number to it's ordinal
// suffix (ie 24 = "24th");

export function numToOrdinalSuffix(i) {
  const j = i % 10;
  const k = i % 100;

  if (j == 1 && k != 11) {
    return `${i}st`;
  }

  if (j == 2 && k != 12) {
    return `${i}nd`;
  }

  if (j == 3 && k != 13) {
    return `${i}rd`;
  }

  return `${i}th`;
} // Captialize the first letter of each word in given string

export function titleize(params) {
  return `${params || ''}`.split(' ').map(s => `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`).join(' ');
} // Convert a zero indexed number to its' ordinal

export function toOrdinal(index) {
  const result = ordinals[index];
  if (!result) throw Error(`${PREFIX} ordinal not defined for index: ${index}`);
  return result;
} // Alias

export const ordinalize = toOrdinal; // Replace all "-" dash characters with spaces

export function dedasherize(params) {
  return `${params || ''}`.replace(/-/g, ' ');
} // Extract hostname from a url

export function getHostname(url) {
  let hostname = '';

  try {
    hostname = new URL(url).hostname;
  } catch (err) {
    throw createWrappedError(`${PREFIX} invalid url: "${url}", missing protocal https://?`, err);
  }

  return hostname.replace(/^www\./, '');
} // Remove human-unreadable Unicode characters

export function stripUnicodeChars(value) {
  // eslint-disable-next-line
  return value.replace(/[^\x00-\x7F]/g, '');
} // Convert a string to-kabob-case

export function kebabCase(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[\s_]+/g, '-').toLowerCase();
} // Replace Dashed/Kabob casing
// with space separated strings

export function humanize(str) {
  return dedasherize(kebabCase(str));
} // Format number
// 1000 -> 1,000

export function formatNumber(num) {
  return new Intl.NumberFormat().format(num);
} // Check if a string is upper case

export function isUpperCase(str) {
  var _str$;

  return (str === null || str === void 0 ? void 0 : str[0]) === (str === null || str === void 0 ? void 0 : (_str$ = str[0]) === null || _str$ === void 0 ? void 0 : _str$.toUpperCase());
} // Attempt to decode or return original string

export function safelyDecodeURIComponent(str) {
  try {
    return decodeURIComponent(str);
  } catch (err) {
    return str;
  }
}