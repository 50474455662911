import { ThemeId } from '@/interfaces/theme';
import { getObjectDiff } from '@/utils/objects';
import shadcn from './shadcn';
import universalDesign from './universalDesign';
export const themes = [universalDesign.light, universalDesign.dark, shadcn.light, shadcn.dark];
export const defaultTheme = universalDesign.light; // Default fonts for the app
// Used as a fallback when no fonts are loaded

export const defaultFonts = universalDesign.light.fonts;
export const defaultThemePreviews = {
  [ThemeId.UniversalDesignLight]: {
    img: '/img/theme/universal-light.webp'
  },
  [ThemeId.UniversalDesignDark]: {
    img: '/img/theme/universal-dark.webp'
  },
  [ThemeId.ShadcnLight]: {
    img: '/img/theme/shadcn-light.webp'
  },
  [ThemeId.ShadcnDark]: {
    img: '/img/theme/shadcn-dark.webp'
  }
}; // Map of theme previews for each section type

export const themePreviewsMap = {
  LineChart: {
    [ThemeId.UniversalDesignLight]: {
      img: '/img/theme/line-default-light.webp'
    },
    [ThemeId.UniversalDesignDark]: {
      img: '/img/theme/line-default-dark.webp'
    },
    [ThemeId.ShadcnLight]: {
      img: '/img/theme/line-shadcn-light.webp'
    },
    [ThemeId.ShadcnDark]: {
      img: '/img/theme/line-shadcn-dark.webp'
    }
  },
  BarChart: {
    [ThemeId.UniversalDesignLight]: {
      img: '/img/theme/bar-default-light.webp'
    },
    [ThemeId.UniversalDesignDark]: {
      img: '/img/theme/bar-default-dark.webp'
    },
    [ThemeId.ShadcnLight]: {
      img: '/img/theme/bar-shadcn-light.webp'
    },
    [ThemeId.ShadcnDark]: {
      img: '/img/theme/bar-shadcn-dark.webp'
    }
  },
  StackedBarChart: {
    [ThemeId.UniversalDesignLight]: {
      img: '/img/theme/bar-default-light.webp'
    },
    [ThemeId.UniversalDesignDark]: {
      img: '/img/theme/bar-default-dark.webp'
    },
    [ThemeId.ShadcnLight]: {
      img: '/img/theme/bar-shadcn-light.webp'
    },
    [ThemeId.ShadcnDark]: {
      img: '/img/theme/bar-shadcn-dark.webp'
    }
  },
  PieDoughnut: {
    [ThemeId.UniversalDesignLight]: {
      img: '/img/theme/pie-default-light.webp'
    },
    [ThemeId.UniversalDesignDark]: {
      img: '/img/theme/pie-default-dark.webp'
    },
    [ThemeId.ShadcnLight]: {
      img: '/img/theme/pie-shadcn-light.webp'
    },
    [ThemeId.ShadcnDark]: {
      img: '/img/theme/pie-shadcn-dark.webp'
    }
  },
  Scatter: {
    [ThemeId.UniversalDesignLight]: {
      img: '/img/theme/scatter-default-light.webp'
    },
    [ThemeId.UniversalDesignDark]: {
      img: '/img/theme/scatter-default-dark.webp'
    },
    [ThemeId.ShadcnLight]: {
      img: '/img/theme/scatter-shadcn-light.webp'
    },
    [ThemeId.ShadcnDark]: {
      img: '/img/theme/scatter-shadcn-dark.webp'
    }
  },
  Bubble: {
    [ThemeId.UniversalDesignLight]: {
      img: '/img/theme/bubble-default-light.webp'
    },
    [ThemeId.UniversalDesignDark]: {
      img: '/img/theme/bubble-default-dark.webp'
    },
    [ThemeId.ShadcnLight]: {
      img: '/img/theme/bubble-shadcn-light.webp'
    },
    [ThemeId.ShadcnDark]: {
      img: '/img/theme/bubble-shadcn-dark.webp'
    }
  },
  GaugeChart: {
    [ThemeId.UniversalDesignLight]: {
      img: '/img/theme/gauge-default-light.webp'
    },
    [ThemeId.UniversalDesignDark]: {
      img: '/img/theme/gauge-default-dark.webp'
    },
    [ThemeId.ShadcnLight]: {
      img: '/img/theme/gauge-shadcn-light.webp'
    },
    [ThemeId.ShadcnDark]: {
      img: '/img/theme/gauge-shadcn-dark.webp'
    }
  }
}; // Prepares a theme setup to update the selected theme values.
// Identifies only the style properties that are different from the default theme.

export function changeTheme(theme) {
  const differedStyles = getObjectDiff(theme.styles, defaultTheme.styles);
  return {
    theme: theme.id,
    stylesTheme: differedStyles,
    colorPallet: theme.colorPallets[0].colors
  };
}