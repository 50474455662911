import generalConfig from '@/config/general';
export default {
  createdAt: 1693718400,
  updatedAt: 1734538091,
  slug: 'scatter-plot-maker',
  heading: 'Scatter Plot Maker',
  title: 'Online Scatter Plot Maker - Free, No Signup',
  description: 'The most robust online scatter plot tool. Upload your Excel and Google Sheets, design, and download your free image in any size - zero signup necessary.',
  shortDescription: 'Create a free scatter plot. Import data from Excel & Google Sheets. Customize then download below.',
  directory: 'Charts',
  directoryUrl: '/charts',
  images: {
    resourceUrl: '/img/pages/scatter-plot-maker/featured-image.webp',
    resourceUrlAlt: 'Scatter plot below a hovering selection of Excel cells',
    twitterPost: '/img/pages/scatter-plot-maker/twitter-post.webp',
    openGraph: '/img/pages/scatter-plot-maker/og-image.webp'
  },
  icon: '/icons/chart-scatter.svg',
  reviews: [],
  articleSections: [{
    id: 'what-are-scatter-plot-charts',
    name: 'What are Scatter Plot Charts?'
  }, {
    id: 'why-are-scatter-plot-charts-beneficial',
    name: 'Why Are Scatter Plot Charts Beneficial?'
  }, {
    id: 'when-to-use-a-scatter-plot-graph',
    name: 'When to Use a Scatter Plot Graph?'
  }, {
    id: 'how-to-make-a-scatter-plot-with-a-scatter-graph-maker',
    name: 'How to Make a Scatter Plot with a Scatter Graph Maker?'
  }, {
    id: 'how-to-make-a-scatter-plot-in-google-sheets',
    name: 'How to Make a Scatter Plot in Google Sheets'
  }, {
    id: 'how-to-make-a-scatter-plot-in-excel',
    name: 'How to Make a Scatter Plot in Excel'
  }, {
    id: 'free-scatter-plot-maker',
    name: `${generalConfig.productName} Free Scatter Plot Maker`
  }]
};