export default {
  styles: {
    gridMaxColumns: '3',
    paddingTop: '0px',
    paddingRight: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px'
  },
  stylesMediumUp: {},
  stylesMedium: {},
  stylesLarge: {},
  stylesMainSections: {},
  stylesMainSectionsMediumUp: {},
  stylesMainSectionsMedium: {},
  stylesMainSectionsLarge: {}
};